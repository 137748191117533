.App {
    font-family: Montserrat, Segoe UI, PingFang SC, Helvetica Neue, Roboto, Helvetica, Arial, Segoe UI Symbol, sans-serif;
}

.ant-layout-sider, .ant-layout-sider-dark, .ant-layout-sider-has-trigger {
    flex: 0 0 220px;
    max-width: 220px;
    min-width: 220px;
    width: 220px;
}

.ant-layout-sider-trigger {
  background-color: #001529 !important;
}

.ant-menu-sub.ant-menu-inline {
  background-color: #001529 !important;
}

.ant-card {
  box-shadow: rgba(115, 162, 208, 0.1) 0px 23px 20px -20px, rgba(115, 162, 208, 0.06) 0px 0px 15px 0px;
}

.ant-select-selector {
  background-color: transparent !important;
}

.ant-input, .ant-select-selection, .ant-input-search, .ant-select, .ant-input-affix-wrapper, .ant-picker {
  background-color: #e9ecef !important;
}

.ant-input-number {
  background-color: #e9ecef !important;
}

.ant-upload-list-item-info {
  border-radius: 4px;
}

.ant-upload-select-picture-card {
  width: 100% !important;
}

.ant-upload-list-item, .ant-upload-list-picture-card {
  padding: 0px !important;
  border: 0px !important;
  border-radius: 5px !important;
}

.ant-table-small {
  border: none !important;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}

.ant-table-thead {
  text-transform: uppercase;
}

.ant-table-thead > tr > th {
  background-color: #fff !important;
}

.ant-upload.ant-upload-select-picture-card {
  background-color: #e9ecef !important;
}

.ant-upload.ant-upload-drag {
  background-color: #e9ecef !important;
}

.ant-menu-item-selected.custom-navbar {
  background-color: #002140 !important;
  /* background-color: #001529 !important; */
}

.ant-menu-item .custom-navbar.ant-menu-item-active .ant-menu-item-selected {
  background-color: #001529 !important;
}

.custom-navbar.ant-menu-item-active, .ant-menu-submenu-active { 
  background-color: #001529 !important;
}

div.ant-drawer-header {
  padding: 0px;
}

div.ant-modal-header {
  padding: 0px;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-form-item-label {
  /* text-transform: uppercase; */
  font-weight: 400;
  color: #3c484f;
  line-height: 30px !important;
}

.ant-switch-checked, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before  {
  background-color: #43c463 !important;
  border-color: #43c463 !important;
}

.ant-collapse-borderless {
    background-color: #f9fbfd !important;
}

.ant-btn-primary, .ant-radio-button-checked, .ant-radio-button-wrapper-checked { 
  background-color: #43c463 !important;
  border-color: #43c463 !important;
  color: #fff;
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #43c463 !important;
  background-color: transparent !important;
}

#help-menu {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
  color: #fff;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f8f9fa;
}

.sc-htoDjs.react-trello-board.sc-dnqmqq.eoPYBZ {
  min-width: 320px;
}

.sc-fzXfLP.gFJOQf::-webkit-scrollbar-track
{
    background-color: #f4f4f4;
    border-radius: 3px;
}

.sc-fzXfLP.gFJOQf::-webkit-scrollbar
{
    width: 10px;
    background-color: #F5F5F5;
    border-radius: 3px;
}

.sc-fzXfLP.gFJOQf::-webkit-scrollbar-thumb
{
    background-color:#ddd;
    border-radius: 3px;
}

#delete-todo-item:hover {
  display: block;
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

/* div.form-section-header {
  text-align: center;
}

:root {
  --amplify-primary-color: #43c463;
  --amplify-primary-tint: #43c463;
  --amplify-primary-shade: #43c463;

  --amplify-secondary-color: #152939;
  --amplify-secondary-contrast: #ffff;
  --amplify-secondary-tint: #31465f;
  --amplify-secondary-shade:	#1F2A37;
  --amplify-tertiary-color:	#5d8aff;
  --amplify-tertiary-contrast:	var(–amplify-white);
  --amplify-tertiary-tint:	#7da1ff;
  --amplify-tertiary-shade:	#537BE5;
  --amplify-grey:	#828282;
  --amplify-light-grey:	#c4c4c4;
  --amplify-white:	#ffffff;
  --amplify-red:	#dd3f5b;

  --amplify-font-family: Montserrat;
  --amplify-text-xxs: 0.75rem;
  --amplify-text-xs: 0.81rem;
  --amplify-text-sm: 0.875rem;
  --amplify-text-md: 1.2rem;
  --amplify-text-lg: 1.5rem;
  --amplify-text-xl: 2rem;
  --amplify-text-xxl: 2.5rem
} */